import { graphql, useStaticQuery } from 'gatsby'
import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import { ButtonSlider, Layout } from '../components'
import { ArrowRight, ArrowUp, ArrowDown } from '../components/icons'
import YearSlider from '../components/year-slider'
import { black, white } from '../styling/colors'
import { When } from '../styling/when'
import Img from 'gatsby-image'
import Modal from '../components/modal'
import { useMediaMatch } from '../util'
import { motion } from 'framer-motion'

const TitleSection = styled.div`
  /* margin-top: 24px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  // flex: 1;
`
const ContentSection = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: flex-end;
  // align-items: flex-start;
  ${When.Large} {
    flex-direction: row;
    justify-content: flex-start;
  }
`

const Title = styled.h1`
  color: ${white};
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: center;
  margin: 0;

  ${When.Large} {
    display: block;
    font-size: 48px;
    font-family: 'poppins';

    margin: 18px 0 0 0;
  }
`

const SubText = styled.p`
  color: ${white};
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;

  ${When.Large} {
    display: block;
    font-size: 14px;
  }
`

const YearSliderContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 550px;
  height: 140px;

  ${When.Large} {
    width: 140px;
    margin-left: 24px;
    height: 348px;
  }
`

const SlideButtonWrapper = styled.div`
  justify-content: flex-end;
  padding-right: 4px;
  display: none;

  ${When.Large} {
    display: flex;
  }
`

const SliderButton = styled.button`
  height: 40px;
  width: 40px;
  outline: 0;
  border: 0;
  background: none;
  cursor: pointer;
  display: block;
`

const ImageContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
  margin-bottom: 50px;

  ${When.Large} {
    justify-content: flex-end;
    margin-bottom: 0;
  }
`

const StyledButtonSliderLeft = styled(ButtonSlider)`
  position: absolute;
  left: 28px;
  display: block;

  ${When.Large} {
    display: none;
  }
`

const StyledButtonSliderRight = styled(ButtonSlider)`
  position: absolute;
  right: 28px;
  display: block;

  ${When.Large} {
    display: none;
  }
`

const TextContent = styled(motion.div)`
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 289px;

  ${When.Large} {
    margin-left: 24px;
  }
`

const ImageWrapper = styled.div`
  position: relative;
  width: 175px;
  height: 175px;

  ${When.Large} {
    width: 256px;
    height: 256px;
  }
`

const SmallCircle = styled.div`
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${white};

  ${When.Large} {
    left: 0;
    top: 50%;
  }
`

const FadingLine = styled.div`
  position: absolute;
  background: linear-gradient(
    to top,
    rgba(250, 250, 250, 0) 0.2%,
    #fafafa 92.21%
  );
  left: 50%;
  top: 100%;
  width: 1px;
  height: 53px;

  ${When.Large} {
    background: linear-gradient(
      to right,
      rgba(250, 250, 250, 0) 0.2%,
      #fafafa 92.21%
    );
    transform: translateX(-100%);
    width: 189px;
    height: 1px;
    top: 50%;
    left: 0;
  }
`

const BorderBackground = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: linear-gradient(to top, #fafafa 0%, rgba(250, 250, 250, 0) 100%);

  ${When.Large} {
    background: linear-gradient(
      to right,
      #fafafa 0%,
      rgba(250, 250, 250, 0) 100%
    );
  }
`

const BorderCover = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  border-radius: 50%;
  background: ${black};
`

const RoundImageContainer = styled(motion.div)`
  width: 151px;
  height: 151px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;

  display: ${({ show }) => (show ? 'block' : 'none')};

  ${When.Large} {
    width: 224px;
    height: 224px;
  }
`

const ContentHeading = styled(motion.div)`
  color: ${white};
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 31px;

  ${When.Large} {
    font-family: Poppins;
    font-size: 24px;
    line-height: 36px;
    text-align: left;
    margin-bottom: 0;
  }
`

const ContentExcerpt = styled(motion.div)`
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 16px;
  display: none;

  ${When.Large} {
    display: block;
  }
`

const ReadMore = styled(motion.div)`
  display: flex;
  align-items: center;
  cursor: pointer;
  display: none;

  ${When.Large} {
    display: flex;
  }
`

const OurStory = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${white};
  margin-bottom: 24px;

  ${When.Large} {
    margin-bottom: 0;
  }
`

const ReadMoreText = styled.div`
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 8px;
`

const TemporaryTitleSection = styled.div`
  /* margin-top: 24px; */
  padding-top: 64px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  flex: 1;
`

const TemporaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  height: 100%;
  color: ${white};
  text-align: justify;

  ${Title} {
    color: ${black};
  }

  ${SubText} {
    color: ${black};
  }
`

const TemporaryTextContent = styled.div`
  color: ${black};
  margin: 0 10%;
  font-size: 14px;
  line-height: 20px;

  ${When.Medium} {
    margin: 0;
    width: 50%;
  }
`

const ModalContent = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  display: ${({ show }) => (show ? 'block' : 'none')};
  z-index: 1000;
`

const verticalStyleMap = [
  { margin: 0, fontSize: '24px' },
  { margin: 0, fontSize: '32px' },
  { margin: 0, fontSize: '48px', fontWeight: 700 },
  { margin: 0, fontSize: '32px' },
  { margin: 0, fontSize: '24px' },
]

const horizontalStyleMap = [
  { margin: 0, fontSize: '24px' },
  { margin: 0, fontSize: '32px', transform: 'translateX(-15%)' },
  { margin: 0, fontSize: '48px', fontWeight: 700 },
  { margin: 0, fontSize: '32px', transform: 'translateX(15%)' },
  { margin: 0, fontSize: '24px' },
]

function truncate(input) {
  if (input.length > 150) {
    return input.substring(0, 150) + '...'
  }
  return input
}

export default function About({ location }) {
  const [[scrollForward, scrollBackward], setCallbacks] = useState<any>([])
  const [currentSummary, setCurrentSummary] = useState(null)
  const [currentYear, setCurrentYear] = useState<string>()

  const { large } = useMediaMatch()
  const handleCallbacks = useCallback(args => setCallbacks(args), [])

  const handleCloseModal = useCallback(() => setCurrentSummary(null), [
    setCurrentSummary,
  ])

  const {
    allStrapiYearSummary: { edges },
  } = useStaticQuery(query)

  const summaries = useMemo(
    () =>
      edges.map(
        ({
          node: {
            title,
            excerpt,
            year,
            fields: { contentParsed },
            ...rest
          },
        }) => ({
          title,
          excerpt,
          year,
          content: contentParsed,
          imageLarge: rest?.photo?.localFile?.childImageSharp?.fixedLarge,
          imageSmall: rest?.photo?.localFile?.childImageSharp?.fixedSmall,
        })
      ),
    []
  )

  const summaryByYear = useMemo(
    () =>
      summaries.reduce(
        (prev, curr) => ({ ...prev, ...{ [curr.year]: curr } }),
        {}
      ),
    [summaries]
  )

  const years = useMemo(() => Object.keys(summaryByYear).map(y => Number(y)), [
    summaryByYear,
  ])

  const imageVariants = {
    visible: { transition: { duration: 0.5 }, opacity: 1, display: 'block' },
    hidden: { opacity: 0, display: 'none' },
  }

  const textContainerVariants = {
    visible: {
      opacity: 1,
      display: 'block',
      transition: {
        when: 'beforeChildren',
        // delay: 1,
        staggerChildren: 0.1,
      },
    },
    hidden: {
      opacity: 0,
      display: 'none',
      transition: {
        // staggerChildren: 0.2,
        // when: 'afterChildren',
      },
    },
  }

  const textItemVariants = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: 20 },
  }

  const enable = false
  return (
    <Layout location={location} backgroundColor={enable ? black : white} style={{ justifyContent: 'center' }}>
      {enable && (
        <>
          <TitleSection>
            <Title>Who we are</Title>
            <SubText>We create and decorate innovative glass packaging</SubText>
            <OurStory>
              <ArrowRight height={10} width={5} />
              <ReadMoreText>Our Story</ReadMoreText>
            </OurStory>
          </TitleSection>
          <ContentSection>
            <YearSliderContent>
              <SlideButtonWrapper>
                <SliderButton onClick={scrollForward}>
                  <ArrowUp />
                </SliderButton>
              </SlideButtonWrapper>
              <YearSlider
                items={years}
                mode={large ? 'vertical' : 'horizontal'}
                verticalStyleMap={verticalStyleMap}
                horizontalStyleMap={horizontalStyleMap}
                onCallbacks={handleCallbacks}
                onChange={setCurrentYear}
              />

              {/* </YearSliderWrapper> */}
              <SlideButtonWrapper>
                <SliderButton onClick={scrollBackward}>
                  <ArrowDown />
                </SliderButton>
              </SlideButtonWrapper>
            </YearSliderContent>
            <ImageContent>
              {/* <UFO> */}
              <StyledButtonSliderLeft
                onClick={scrollForward}
                direction="left"
                colors="outlineWhiteOnBlack"
              />
              <ImageWrapper>
                <FadingLine />
                <SmallCircle />
                <BorderBackground>
                  <BorderCover>
                    {summaries.map(summary => (
                      <RoundImageContainer
                        onClick={() => setCurrentSummary(summary)}
                        variants={imageVariants}
                        initial="hidden"
                        animate={
                          currentYear === summary.year ? 'visible' : 'hidden'
                        }
                        show={currentYear === summary.year}
                      >
                        <Img
                          fadeIn={false}
                          fixed={
                            large ? summary.imageLarge : summary.imageSmall
                          }
                        />
                      </RoundImageContainer>
                    ))}
                  </BorderCover>
                </BorderBackground>
              </ImageWrapper>
              {/* </UFO> */}
              <StyledButtonSliderRight
                onClick={scrollBackward}
                direction="right"
                colors="outlineWhiteOnBlack"
              />
            </ImageContent>

            {summaries.map(summary => (
              <TextContent
                variants={textContainerVariants}
                initial="hidden"
                animate={currentYear === summary.year ? 'visible' : 'hidden'}
              >
                <ContentHeading variants={textItemVariants}>
                  {summary.title}
                </ContentHeading>
                <ContentExcerpt variants={textItemVariants}>
                  {summary.excerpt && truncate(summary.excerpt)}
                </ContentExcerpt>
                <ReadMore
                  variants={textItemVariants}
                  onClick={() => setCurrentSummary(summary)}
                >
                  <ArrowRight height={10} width={5} />
                  <ReadMoreText>Read More</ReadMoreText>
                </ReadMore>
              </TextContent>
            ))}
          </ContentSection>
        </>
      )}

      {!enable && (
        <TemporaryContainer>
          <TemporaryTitleSection>
            <Title>Who we are</Title>
            <SubText>We create and decorate innovative glass packaging</SubText>
            <TemporaryTextContent>
              <p>
              Our company was established in 2013 with a specific mission in mind: to expand the variety of glassware available on the Georgian market and to improve local distributing experience usually tainted by the delayed shipments from international glass manufacturers. 
              </p>
              <p>
              We’ve kept true to our goal, regularly refreshing and diversifying our stock to ensure our customers’ vision of the final product aligns as much as possible with what we can offer and we can’t say they don’t appreciate it. 
              </p>
              <p>
              Today, we are proud to say that KMC Glass is one of the largest importers of glassware in the Transcaucasian region, serving up to 150 companies of various sizes. As we continue to grow and improve, we invite you to join our journey and become part of it yourself.
              </p>
            </TemporaryTextContent>
          </TemporaryTitleSection>
        </TemporaryContainer>
      )}
      <Modal
        title={currentSummary && currentSummary.title}
        isOpen={!!currentSummary}
        onRequestClose={handleCloseModal}
      >
        {currentSummary && currentSummary.content}
      </Modal>
    </Layout>
  )
}

const query = graphql`
  query {
    allStrapiYearSummary {
      edges {
        node {
          title
          excerpt
          year
          fields {
            contentParsed
          }
          photo {
            localFile {
              childImageSharp {
                fixedLarge: fixed(width: 224, height: 224) {
                  ...GatsbyImageSharpFixed_noBase64
                }
                fixedSmall: fixed(width: 151, height: 151) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`
