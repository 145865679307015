import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { white } from '../../styling/colors'

const ListContainer = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  padding: ${({ mode }) => (mode === 'vertical' ? '20px 0' : '0 20px')};

  // new
  /* display: flex;
  justify-content: flex-end;
  align-items: center; */
`

const List = styled.div`
  display: flex;
  flex-direction: ${({ mode }) => (mode === 'vertical' ? 'column' : 'row')};
  align-items: ${({ mode }) => (mode === 'vertical' ? 'flex-end' : 'center')};
  flex-wrap: nowrap;
  transition: all 0.2s;
  ${({ sizePercent, mode }) =>
    mode === 'vertical'
      ? `height: ${sizePercent}%; width: 100%;`
      : `width: ${sizePercent}%; height: 100%;`}

  transform: ${({ offset, mode }) =>
    mode === 'vertical' ? `translateY(${offset}%)` : `translateX(${offset}%)`};

  // new
  /* position: absolute;
  top: 0; */
`

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${white};
  flex: 0 0 ${({ sizePercent }) => sizePercent}%;

  transition: all 0.2s;
`

const defaultStyleMap = [
  { opacity: 0.1 },
  { opacity: 0.5 },
  { opacity: 1 },
  { opacity: 0.5 },
  { opacity: 0.1 },
]

interface Props {
  items: string[] | number[]
  // TODO: Add types
  verticalStyleMap?: any[]
  horizontalStyleMap?: any[]
  onCallbacks?: (callbacks: any) => void
  onChange?: (currentItem: any) => void
  mode?: 'horizontal' | 'vertical'
  className?: string
}

const ITEMS_TO_SHOW = 5

// vertical - container should have a fixed height
// horizontal - container should have a fixed width
export default function YearSlider({
  items,
  verticalStyleMap = [],
  horizontalStyleMap = [],
  onCallbacks,
  onChange,
  className,
  mode = 'vertical',
}: Props) {
  const [currentPosition, setCurrentPosition] = useState<number>(
    Math.floor(ITEMS_TO_SHOW / 2)
  )

  const emptySpace = Math.floor(ITEMS_TO_SHOW / 2)
  const centerItem = Math.ceil(ITEMS_TO_SHOW / 2)
  const canScrollForward = currentPosition < emptySpace
  const canScrollBackwad = currentPosition + items.length > centerItem

  useEffect(
    () => onChange && onChange(items[centerItem - currentPosition - 1]),
    [centerItem, currentPosition]
  )

  //TODO: this causes resetting on about page
  useEffect(() => {
    if (items.length < centerItem) {
      const correctedPosition = centerItem - items.length
      if (currentPosition < correctedPosition) {
        setCurrentPosition(correctedPosition)
      }
    }
  }, [centerItem, items, currentPosition])

  const [listSizeCoef, listSize, itemSize] = useMemo(() => {
    // const listSizeCoef = Math.max(items.length / ITEMS_TO_SHOW, 1)
    const listSizeCoef = items.length / ITEMS_TO_SHOW
    const listSize = 100 * listSizeCoef
    const itemSize = 100 / ITEMS_TO_SHOW / listSizeCoef

    return [listSizeCoef, listSize, itemSize]
  }, [items])

  const offset = useMemo(() => currentPosition * itemSize, [
    currentPosition,
    itemSize,
  ])

  const handleScrollBackward = useCallback(
    e => {
      if (canScrollBackwad) {
        setCurrentPosition(currentPosition - 1)
      }
    },
    [canScrollBackwad, currentPosition]
  )

  const handleScrollForward = useCallback(
    e => {
      if (canScrollForward) {
        setCurrentPosition(currentPosition + 1)
      }
    },
    [canScrollForward, currentPosition]
  )

  const getStyles = useCallback(
    index => {
      const defaultStyles = defaultStyleMap[currentPosition + index]
      const passed = mode === 'vertical' ? verticalStyleMap : horizontalStyleMap
      const passedStyles = passed[currentPosition + index]

      if (!defaultStyles && !passedStyles) {
        return { opacity: 0 }
      }

      return { ...passedStyles, ...defaultStyles }
    },
    [currentPosition, defaultStyleMap, verticalStyleMap, mode]
  )

  useEffect(
    () =>
      onCallbacks && onCallbacks([handleScrollForward, handleScrollBackward]),
    [onCallbacks, handleScrollForward, handleScrollBackward]
  )

  return (
    <ListContainer className={className} mode={mode}>
      <List offset={offset} sizePercent={listSize} mode={mode}>
        {items.map((itm, index) => (
          <Item
            style={getStyles(index)}
            sizePercent={itemSize}
            key={itm}
            mode={mode}
          >
            {itm}
          </Item>
        ))}
        <Item
          style={{
            width: 0,
            visibility: 'hidden',
            position: 'relative',
            zIndex: -1,
          }}
        >
          {items[0]}
        </Item>
      </List>
    </ListContainer>
  )
}
