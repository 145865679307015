import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import ReactModal from 'react-modal'
import { black, white } from '../../styling/colors'
import ButtonIcon from '../button-icon'
import { Cross } from '../icons'
import { motion } from 'framer-motion'
import { When } from '../../styling/when'

interface Props {
  title?: string
  onRequestClose?: () => void
  [key: string]: any
}

const SideContainer = styled(motion.div)`
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  background-color: ${white};
  overflow: hidden;

  ${When.Medium} {
    width: 560px;
  }

  /* display: ${({ show }) => (show ? 'block' : 'none')}; */
`

const TitleArea = styled.div`
  width: 100%;
  height: 80px;
  background: #f5f5f5;
  display: flex;
  align-items: center;
  padding: 0 48px;
  box-sizing: border-box;
`

const TitleText = styled.div`
  flex: 1;
`

const ContentArea = styled.div`
  width: 100%;
  padding: 24px 48px 0 48px;
  box-sizing: border-box;
`

export default function Modal({
  children,
  title,
  onRequestClose,
  ...props
}: Props) {
  const [showSidebar, setShowSidebar] = useState(false)

  const handleClose = useCallback(() => onRequestClose && onRequestClose(), [
    onRequestClose,
  ])

  return (
    <ReactModal
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          background: 'rgba(0, 0, 0, 0.8)',
          zIndex: 1000,
          overflow: 'hidden',
        },
        content: {
          background: 'none',
          border: 'none',
          // color: white,
          height: '100%',
          width: '100%',
          inset: 0,
          padding: 0,
          overflow: 'hidden',
        },
      }}
      onAfterOpen={() => setShowSidebar(true)}
      onAfterClose={() => setShowSidebar(false)}
      onRequestClose={onRequestClose}
      {...props}
    >
      {showSidebar && (
        <SideContainer
          initial={{ x: 100, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
        >
          <TitleArea>
            <TitleText>{title}</TitleText>
            <ButtonIcon colors="light" stroke={false} onClick={handleClose}>
              <Cross stroke={black} />
            </ButtonIcon>
          </TitleArea>
          <ContentArea
            dangerouslySetInnerHTML={{
              __html: children,
            }}
          ></ContentArea>
        </SideContainer>
      )}
    </ReactModal>
  )
}
